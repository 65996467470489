<template>
  <div class="log" />
</template>

<script>
import store from '@/store/store'

export default {
  created() {
    store.dispatch('userLogout')
      .then(() => {
        this.$router.push({ name: 'auth' })
      })
  },
}
</script>

<style>
</style>
